@import "../../styles/vars";
@import "../../styles/mixins";

.card {
  display: flex;
  flex-direction: column;
  flex: 0 0;
  gap: 14px;
  z-index: 10;
  align-items: center;
  justify-content: center;

  &_background {
    width: 32rem;
    height: 48rem;
    border-radius: 12px;
    background-color: $default-blue;
    -webkit-clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%, 0 10%);
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%, 0 10%);
    cursor: pointer;

    &-selected {
      background-color: $hover-pink;

      .card_weight {
        background: $hover-pink;
      }
    }

    &-disabled {
      background-color: $disabled-gray;
      cursor: not-allowed;

      .card {
        &_container {
          opacity: 0.5;
        }

        &_subTitle,
        &_title,
        &_info_text {
          color: $disabled-gray;
        }

        &_weight {
          background: $disabled-gray;
          color: #fff;
        }
      }
    }
    @media (max-width: $tablet-width) {
      width: 29rem;
      height: 45rem;
    }
  }

  &_container {
    position: relative;
    top: 4px;
    left: 4px;
    width: 31.2rem;
    height: 47.2rem;
    padding: 2.1rem 1.6rem 0.1rem 5.1rem;
    border-radius: 12px;
    background: $gray;
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%, 0 10%);

    @media (max-width: $tablet-width) {
      width: 28.2rem;
      height: 44.2rem;
    }
  }

  &_subTitle {
    margin-bottom: 5px;
    @include font(1.6rem, 400, 19px);
    color: $dark-gray;

    &-hover {
      color: $hover-pink;
    }
  }

  &_title {
    @include font(4.8rem, 700, 56px);
  }
  &_title-small {
    @include font(2.4rem, 700, 28px);
  }

  &_info {
    margin-top: 1.5rem;
    &_text {
      @include font(1.4rem, 400, 16px);
      color: $dark-gray;
    }
  }

  &_image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 1.2rem;
  }

  &_weight {
    position: absolute;
    right: 1.2rem;
    bottom: 1.2rem;
    width: 8rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    padding: 2rem 0 0.8rem;
    border-radius: 50%;
    background: $default-blue;
    @include font(4.2rem, 400, 2.2rem);
    text-align: center;
    color: #fff;

    &-small {
      font-size: 2.1rem;
    }
  }

  &_under {
    @include font(1.3rem, 400, 1.5rem);
    text-align: center;
    color: #fff;

    &-disabled {
      color: $disabled-yellow;
    }
  }

  &_button {
    color: $default-blue;
    border: none;
    padding: 0 0.2rem;

    &-dashed {
      border-bottom: 0.1rem dashed $default-blue;
    }
  }
}
