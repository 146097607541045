$gray: #f2f2f2;
$dark-gray: #666666;
$disabled-gray: #c9c9c9;
$default-blue: #1698d9;
$hover-pink: #d91667;
$disabled-yellow: #ffff66;

$content-width: 1200px;
$tablet-width: 768px;
$mobile-small-width: 320px;
